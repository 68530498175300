import React from 'react'
import './about.css';
import profileImage from "../images/profiel.jpg";

const About = () => {
    return (
        <div className="about">
        <div className="card">
            <div className="profile-sidebar">
                <img className="profile-image" src={profileImage} alt="profiel foto"/>
               <ul className="social-list">
                   <li className="social-item">
                       <a aria-label="mail" className="social-link"  href="mailto:tomy.devogelaere@telenet.be"><i className="far fa-envelope"></i></a>
                   </li>
                   <li className="social-item">
                       <a aria-label="facebook" className="social-link" href="https://www.facebook.com/tomy.devogelaere/" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                   </li>
                   <li className="social-item">
                       <a aria-label="instagram" className="social-link" href="https://www.instagram.com/tomydevogelaere/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                   </li>
               </ul>
            </div>

            <div className="profile-main">
                <h2 className="profile-name">Tomy Devogelaere</h2>
                <p className="profile-position">Live Music Photographer</p>
                <p className="profile-body">
                My name is Tomy Devogelaere and I’ve always been passionate about music. 
                Through my photography I want to capture that magic that only happens at live events. 
                I’m based in Deinze (Belgium) and I am the house photographer of Belgium’s No. 1 music club,  
                    <a href="https://muziekcafeelpee.be/" target="_blank" rel="noreferrer"> Muziek Café Elpee</a>.
                    <br/>
                    I also shoot concerts for <a href="http://www.rocktribune.be/" target="_blank" rel="noreferrer"> Rock Tribune </a>
                    and <a href="https://www.grimmgent.com/" target="_blank" rel="noreferrer"> Grimm Gent </a>webzine.
                </p>
            </div>
        </div>
           
                
        </div>
    )
}

export default About
