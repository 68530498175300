import React from 'react'
import './GalleryCard.css'
import { useHistory} from 'react-router-dom';


function GalleryCard(props) {
const history = useHistory();

function NavigateTo(){
    history.push(props.path);
}

    return (
        <div className="galleryCard">
            <div className="imgBx">
                <img src={props.galleryImage} alt="images" onClick={NavigateTo}/>
            </div>
            <div className="details">
                <h2>
                    {props.title}<br/>
                    <span>{props.year}</span>
                    <span id="hidden">{props.path}</span>
                </h2>
            </div>
      </div>

    )
}

export default GalleryCard
