import './App.css';
import React from 'react';
import './App.css';
import Navbar from './Components/NavBar';
import { Switch, Route, HashRouter } from 'react-router-dom';
import Home from './Components/Home';
import Gallery from './Components/Gallery';
import PortfolioPage from './Components/PortfolioPage';
import About from './Components/About';




function App() {

  

  return (
    <>
    <HashRouter>
      <Navbar />
      <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/gallery' exact component={Gallery} />
          <Route path='/about' exact component={About}/>
          <Route path='/portfolio/:title' exact component={PortfolioPage}/>
    </Switch>
  </HashRouter>
</>
  );
}

export default App;
