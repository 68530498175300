import React, { useState } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import "./NavBar.css";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    return (
      <>
        <nav className="navbar">
          <div className="navbar-container">
            <Link to="/" className="navbar-logo">
              <img src={logo} alt="My logo" width="200px"/>
            </Link>
            
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "fas fa-times" : "fas fa-bars"} />
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/portfolio/home" className="nav-links" onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/portfolio/latest" className="nav-links" onClick={closeMobileMenu}>
                  Latest
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/portfolio/elpee" className="nav-links" onClick={closeMobileMenu}>
                  Elpee
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/gallery"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Galeries
                </Link>
              </li>
              
              <li className="nav-item">
                <Link
                  to="/about"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  };
  
  export default Navbar;