import React, {useState, useEffect} from 'react';
import GalleryCard from './GalleryCard';
import Axios from 'axios';
import './Gallery.css'

function Gallery() {

    const [cards, setCards] = useState([]);
    const url ="https://tomydevogelaere.be/ImagesApi/public/api/cards";
    //const url = "http://localhost:8000/api/cards";

useEffect(() => {
    Axios.get(url).then((res) => {
        setCards(res.data);
    });
}, [url]);

    return (
            <div className="gallery">
                {cards.map((card, i) =>
           
                <GalleryCard galleryImage={`../ImagesApi/storage/app/public/images/${card.imageurl}`}
                title={card.title}
                year={card.subTitle}
                path={card.path}
                key={i}/>
                )}
            
            </div>
    )
}

export default Gallery

//http://localhost:8000/storage/images/