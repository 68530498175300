import React, {useState, useEffect} from 'react';
import './PortfolioPage.css';
import {useParams} from 'react-router-dom';
import Axios from 'axios';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import {SRLWrapper} from 'simple-react-lightbox';

function PortfolioPage() {

    const params = useParams();
    const title = params['title'];
    const  [images, setImages ]= useState([]);

    //const DevelopApiPath = "http://localhost:8000/api/";
    //const DevelopImageStorage = "http://localhost:8000/storage/images/";
    const DeployedApiPath = "https://tomydevogelaere.be/ImagesApi/public/api/";
    const DeployedImageStorage = "../ImagesApi/storage/app/public/images/";
    var url = '';
    var pageTitle = '';
    console.log(title);
    switch(title) {
        case 'elpee':
         url = DeployedApiPath + "entriesForCategory/Elpee";
         pageTitle = "Muziekcafé Elpee";
          break;
        case 'latest':
         url =   DeployedApiPath + "latestEntries";
         pageTitle = "Latest shots";
          break;
          case 'home':
         url =   DeployedApiPath + "entriesHome";
         pageTitle = "Music is Passion!";
          break;
        default:
            url =   DeployedApiPath + "entriesForYear/" + title;
            pageTitle = "Live shots " + title;
      }




      useEffect(() => {
        Axios.get(url
        ).then((res) => {
            setImages(res.data);
        });   
      }, [url]);

      const breakPoints = {
        default: 6,
        1200: 5,
        992: 3,
        768: 2,
        576:1,
        380: 1,
        200: 1,
        50: 1,
    };

    return (

        <SRLWrapper>
        <div className="wrapper">
            <h1>{pageTitle}</h1>
        
        <ResponsiveMasonry columnsCountBreakPoints={breakPoints}>
            <Masonry gutter="20px">
                {images.map((img, i) =>
                <div className="imageContainer" key={img.id}>
                    <a href= {DeployedImageStorage + img.imageurl}>
                        <img 
                        src={DeployedImageStorage + img.imageurl}
                        alt={img.artist}
                        
                        className="image"
                        />
                    </a>
                </div>
                )}
            </Masonry>
        </ResponsiveMasonry>
        </div>
        </SRLWrapper>
    )
}

export default PortfolioPage
